import {initializeApp} from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  getIdToken,
  getIdTokenResult,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthCredential,
  OAuthProvider,
  onIdTokenChanged,
  reauthenticateWithCredential,
  reauthenticateWithRedirect,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updateEmail as firebaseUpdateEmail,
  updatePassword,
  useDeviceLanguage,
} from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import {promptUserForPassword} from 'app/actions/ReAuthenticationActions';
import {clearCurrentUser, updateAuthRedirect, updateCurrentUser} from 'app/actions/currentUserActions';
import queryString from 'query-string';
import {getRemoteAuth, postEmailVerified} from 'app/services/apiBackend';
import {postLogin, setAuthorizationHeader, updateUser} from 'app/services/api';
import {errorAuth} from 'app/actions/AuthPageErrorActions';
import {splitDisplayName} from 'app/components/helpers/helpers';
import {codes} from 'app/components/error/ErrorCodeToMessage';
import {openDialog} from 'app/components/dialog/openDialog';
import {sendEventToGA4, setDimensionToGA4} from 'app/components/helpers/gtagHelpers';
import {push} from 'connected-react-router';
import {setSentryTags} from 'app/services/sentry';
import {debouncePromise} from 'app/components/helpers/debounce';
import MissingEmailException from 'app/exceptions/MissingEmailException';
import AuthenticationException from 'app/exceptions/AuthenticationException';
import DuplicatePromiseException from 'app/exceptions/DuplicatePromiseException';

const nativeProviderConfig = {
  'apple.com': () => {
    return {
      postMessage: 'triggerAppleSignIn',
      setter: 'setAppleToken',
    };
  },
  'google.com': () => {
    return {
      postMessage: 'triggerGoogleSignIn',
      setter: 'setGoogleToken',
    };
  },
  'facebook.com': () => {
    return {
      postMessage: 'triggerFacebookSignIn',
      setter: 'setFacebookToken',
    };
  },
};

let auth, nativeApp;

const initFirebase = (dispatch, jwtCookieName, redirectUrl, districtSlug, nativeInfo) => {
  const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  auth = getAuth(firebaseApp);
  nativeApp = nativeInfo;
  // Register Observers
  checkAuthRedirectResult(redirectUrl, dispatch, jwtCookieName, districtSlug);
  onCurrentUserIdTokenChanged(dispatch, jwtCookieName);
};

const onCurrentUserIdTokenChanged = (dispatch, jwtCookieName) => {
  onIdTokenChanged(auth, user => {
    if (user) {
      if (nativeApp.isNativeApp && user?.refreshToken) {
        nativeApp.login.handler(user.refreshToken);
      }
      // Get IdToken decoded, with claims (including emailVerified) and token base64 encoded
      getIdTokenResult(user).then(decodedIdToken => {
        setDimensionToGA4({user_id: user.uid});
        Sentry.setUser({id: user.uid});
        dispatch(updateCurrentUser({user, claims: decodedIdToken.claims}));
        setAuthorizationHeader(decodedIdToken.token);
      });
    } else {
      setAuthorizationHeader(null);
      Sentry.setUser(null);
      // User has no valid credentials
      dispatch(clearCurrentUser());
    }

    // Sets cookie with current user data for server side services, e.g. old world
    if (jwtCookieName) {
      if (user) {
        getIdTokenResult(user).then(decodedToken => {
          setJwtCookie(jwtCookieName, decodedToken);
        });
      } else {
        deleteJwtCookie(jwtCookieName);
      }
    }
    // Checks for remote authentication, e.g. freshdesk
    // Calls backend with admin sdk to generate target url back to login service
    const search = queryString.parse(window.location.search);
    if (user && search && search.login_service === 'freshdesk') {
      getIdTokenResult(user).then(decodedToken => {
        getRemoteAuth(search.host_url, decodedToken.token)
          .then(result => {
            window.location = result.data.targetUrl;
          })
          .catch(error => console.log(error.message));
      });
    }
  });
};

let actionCodeSettings = {};
if (typeof window !== 'undefined') {
  const search = queryString.parse(window.location.search);
  const redirectUrl = search && search.redirectUrl ? search.redirectUrl : '';
  actionCodeSettings = {
    url: redirectUrl ? window.location.origin + redirectUrl : window.location.origin,
  };
}

const captureErrorSendingEmailVerification = (extra = {}) => {
  Sentry.captureException(new Error('Error trying to automatically send a verification mail'), {extra});
};

const EmailAndPasswordSignUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password).then(result => {
    sendEmailVerification(result.user, actionCodeSettings).catch(error =>
      captureErrorSendingEmailVerification({error})
    );
  });
};

const EmailAndPasswordLogin = (email, password) => {
  // No need to set persistence
  // The default for web browser and React Native apps is local
  // Meaning a user's session persists even after the user closes the browser.
  return signInWithEmailAndPassword(auth, email, password);
};

const authWithProvider = (provider, dispatch, redirectUrl, jwtCookieName, district) => {
  // Need to work with persistent storage after full page reload, to show loading skeleton, sets cookie 'auth-redirect'
  dispatch(updateAuthRedirect(true));
  useDeviceLanguage(auth);
  // No need to set persistence
  // The default for web browser and React Native apps is local
  // Meaning a user's session persists even after the user closes the browser.

  handleSignInWithPopup(auth, provider, dispatch, redirectUrl, jwtCookieName, district);
};

const triggerNativeFederatedProvider = async (
  postMessage,
  setter,
  provider,
  redirectUrl,
  dispatch,
  jwtCookieName,
  district
) => {
  const handleNativeSignInResponse = (token, iOSNativeDisplayName) => {
    const oAuthToken = setter === 'setAppleToken' ? {idToken: token} : token;
    const credential = provider.credential(oAuthToken);
    signInWithCredential(auth, credential)
      .then(userCredential =>
        handleFederatedLoginSuccess(
          userCredential,
          redirectUrl,
          dispatch,
          jwtCookieName,
          district,
          iOSNativeDisplayName
        )
      )
      .catch(error => handleFederatedLoginError(error, dispatch));
  };
  // handle login
  const response = await nativeApp.triggerSignIn.handler(postMessage);
  console.log('### flutter response triggerSignIn for', postMessage, ': ', JSON.stringify(response));
  if (response?.token) {
    const {token, name} = response;
    handleNativeSignInResponse(token, name);
  }
  window[setter] = (token, iOSNativeDisplayName) => {
    handleNativeSignInResponse(token, iOSNativeDisplayName);
  };
};

const GoogleLogin = (redirectUrl, dispatch, jwtCookieName, district) => {
  const providerId = 'google.com';
  if (nativeApp.isNativeApp) {
    const {postMessage, setter} = nativeProviderConfig[providerId]();
    triggerNativeFederatedProvider(
      postMessage,
      setter,
      GoogleAuthProvider,
      redirectUrl,
      dispatch,
      jwtCookieName,
      district
    );
  } else {
    authWithProvider(getProviderForProviderId(providerId), dispatch, redirectUrl, jwtCookieName, district);
  }
};

const AppleLogin = (redirectUrl, dispatch, jwtCookieName, district) => {
  const providerId = 'apple.com';
  const AppleProvider = getProviderForProviderId(providerId);
  if (nativeApp.isNativeApp) {
    const {postMessage, setter} = nativeProviderConfig[providerId]();
    triggerNativeFederatedProvider(postMessage, setter, AppleProvider, redirectUrl, dispatch, jwtCookieName, district);
  } else {
    authWithProvider(AppleProvider, dispatch, redirectUrl, jwtCookieName, district);
  }
};

const FacebookLogin = (redirectUrl, dispatch, jwtCookieName, district) => {
  const providerId = 'facebook.com';
  if (nativeApp.isNativeApp) {
    const {postMessage, setter} = nativeProviderConfig[providerId]();
    triggerNativeFederatedProvider(
      postMessage,
      setter,
      FacebookAuthProvider,
      redirectUrl,
      dispatch,
      jwtCookieName,
      district
    );
  } else {
    authWithProvider(getProviderForProviderId(providerId), dispatch, redirectUrl, jwtCookieName, district);
  }
};

const removePendingCredential = () => window.sessionStorage.removeItem('pendingCred');

const linkPendingCredential = async (pendingCredential, user) => {
  // Step 4b.
  // Link to Google credential.
  // As we have access to the pending credential, we can directly call the link method.
  const credentials = OAuthCredential.fromJSON(pendingCredential);
  try {
    await linkWithCredential(user, credentials);
    // Account successfully linked to the existing Firebase user.
    removePendingCredential();
  } catch (e) {
    Sentry.addBreadcrumb({
      category: 'handleFederatedLoginSuccess',
      message: 'linkPendingCredential',
      level: 'error',
      data: {
        user,
        pendingCredential,
      },
    });
    Sentry.captureException(e);
  }
};

const completeProfile = async (
  displayName,
  district,
  token,
  providerId,
  user,
  additionalUserInfo,
  dispatch,
  redirectUrl,
  jwtCookieName
) => {
  // Complete Profile for federated provider automatically
  const {firstName, lastName} = splitDisplayName(displayName);

  try {
    await updateUser(firstName, lastName, district, token);

    setSentryTags({name: 'sign_up_step', value: 'profile_complete'});
    setSentryTags({name: 'method', value: providerId});

    sendEventToGA4('profile_complete', {method: providerId});
    // Need to force token refresh, otherwise claims.u is not set at caller
    await forceTokenRefresh(user);
    if (additionalUserInfo?.isNewUser) {
      // Need to use router action to avoid reload of page
      dispatch(push('/auth/favorite-team'));
    } else {
      redirectBackToCaller(user, redirectUrl, jwtCookieName, providerId, null);
    }
  } catch (error) {
    dispatch(updateAuthRedirect(false));
    Sentry.addBreadcrumb({
      category: 'updateUser',
      message: 'handleFederatedLoginSuccess',
      level: 'error',
      data: {
        responseData: error.response?.data,
        requestData: {firstName, lastName, district, token},
      },
    });
    Sentry.setTag('providerId', providerId);
    Sentry.captureException(error);
    throw error;
  }
};

const handleUserWithoutEmail = async (dispatch, providerId) => {
  // Show MissingEmailException only for sign up and not for login otherwise a user is not able to complete its profile
  // FIXME: DV-4261 Workaround to handle accounts without email
  sendEventToGA4('sign_up_without_email', {method: providerId});
  dispatch(updateAuthRedirect(false));
  try {
    await signOut(auth);
  } catch (e) {}
  throw new MissingEmailException();
};

const verifyUserMail = async (token, claims, user, redirectBack, handleCompleteProfile) => {
  try {
    await postEmailVerified(token);
    if (claims && !claims.u) {
      // Refresh token after email verified successfully to complete profile
      const refreshedToken = await forceTokenRefresh(user);
      return handleCompleteProfile(refreshedToken);
    }
    redirectBack();
  } catch (error) {
    if (error.response?.status === 403) {
      redirectBack();
    }
    throw error;
  }
};

const handleUserWithEmail = async (user, redirectBack, handleCompleteProfile, dispatch, providerId) => {
  try {
    const idToken = await getIdTokenResult(user);
    const {token, claims} = idToken;

    if (!user.emailVerified) {
      return verifyUserMail(token, claims, user, redirectBack, handleCompleteProfile);
    } else if (claims && !claims.u) {
      return await handleCompleteProfile(token);
    }
    redirectBack();
  } catch (error) {
    const errorData = {
      code: error.response.status,
      message: error.response?.data?.message || JSON.stringify(error.response),
    };
    dispatch(errorAuth(errorData));
    logLoginError(errorData, null, providerId);
  }
};

const handleFederatedLoginSuccess = (result, redirectUrl, dispatch, jwtCookieName, district, iOSNativeDisplayName) => {
  const pendingCred = JSON.parse(window.sessionStorage.getItem('pendingCred'));
  if (!result) {
    // 1. Hide skeleton on init render of AuthPage if user has still a valid token but enters the login flow again
    // 2. Clear cookie if still set on init request if no redirect result is pending
    return dispatch(updateAuthRedirect(false));
  }
  const {user, providerId} = result;
  const additionalUserInfo = getAdditionalUserInfo(result);

  const redirectBack = () => redirectBackToCaller(user, redirectUrl, jwtCookieName, providerId ?? null);

  if (user?.email && pendingCred) {
    linkPendingCredential(pendingCred, user).then(redirectBack);
  } else if (user?.accessToken) {
    const displayName = user.displayName || iOSNativeDisplayName;

    let authAction = additionalUserInfo?.isNewUser ? 'sign_up' : 'login';
    sendEventToGA4(authAction, {method: providerId});

    if (authAction === 'sign_up') {
      setSentryTags({name: 'sign_up_step', value: 'firebase_account_created'});
      setSentryTags({name: 'method', value: providerId});
    }

    const handleCompleteProfile = token =>
      completeProfile(
        displayName,
        district,
        token,
        providerId,
        user,
        additionalUserInfo,
        dispatch,
        redirectUrl,
        jwtCookieName
      );

    if (user && additionalUserInfo?.isNewUser && !user.email) {
      handleUserWithoutEmail(dispatch, providerId);
    } else if (displayName && user.email) {
      handleUserWithEmail(user, redirectBack, handleCompleteProfile, dispatch, providerId);
    } else {
      dispatch(push('/auth/complete-profile', {method: providerId}));
    }
  }
};

const handleFederatedLoginError = (error, dispatch, redirectUrl, jwtCookieName, district) => {
  if (error.code === 'auth/account-exists-with-different-credential') {
    handleAccountWithDifferentCredentials(error, dispatch, redirectUrl, jwtCookieName, district);
  } else {
    dispatch(updateAuthRedirect(false));
    // Do not show error, if user cancels authentication
    if (
      error.code !== 'auth/user-cancelled' &&
      error.code !== 'auth/popup-closed-by-user' &&
      error.code !== 'auth/cancelled-popup-request'
    ) {
      dispatch(errorAuth(error));
      logLoginError(error, null, null);
    }
  }
};

const handleSignInWithPopup = (auth, provider, dispatch, redirectUrl, jwtCookieName, district) => {
  signInWithPopup(auth, provider)
    .then(result => handleFederatedLoginSuccess(result, redirectUrl, dispatch, jwtCookieName, district))
    .catch(error => {
      if (error.code === 'auth/popup-blocked') {
        signInWithRedirect(auth, provider);
      } else {
        handleFederatedLoginError(error, dispatch, redirectUrl, jwtCookieName, district);
      }
    });
};

const checkAuthRedirectResult = (redirectUrl, dispatch, jwtCookieName, district) => {
  getRedirectResult(auth)
    .then(result => handleFederatedLoginSuccess(result, redirectUrl, dispatch, jwtCookieName, district))
    .catch(error => handleFederatedLoginError(error, dispatch, redirectUrl, jwtCookieName, district));
};

const handleAccountWithDifferentCredentials = (error, dispatch, redirectUrl, jwtCookieName, district) => {
  // An error happened.
  // Step 2.
  // User's email already exists.
  // The pending Google/Facebook credential.
  const pendingCred = OAuthProvider.credentialFromError(error);
  window.sessionStorage.setItem('pendingCred', JSON.stringify(pendingCred.toJSON()));
  // The provider account's email address.
  const email = error.customData?.email;
  // Get sign-in methods for this email.
  fetchSignInMethodsForEmail(auth, email).then(function(methods) {
    const providerId = methods[0];
    const providerName = {
      'google.com': 'Google',
      'facebook.com': 'Facebook',
      'apple.com': 'Apple',
      password: 'E-Mail',
    };
    const ConfirmDialog = () => {
      return openDialog({
        resolveAction: 'Fortfahren',
        rejectAction: 'Abbrechen',
        title: 'Konto verbinden',
        text: `Du hast bereits ein ${providerName[providerId]}-Konto. Klicke auf Fortfahren, um dich einzuloggen. Die Konten werden verbunden und du kannst in Zukunft beide Anmeldeoptionen nutzen.`,
      });
    };
    // Step 3.
    // If the user has several sign-in methods,
    // the first method in the list will be the "recommended" method to use.
    if (providerId === 'password') {
      ConfirmDialog()
        .then(() => {
          // Dispatch redux action to ask the user their password.
          dispatch(updateAuthRedirect(false));
          dispatch(promptUserForPassword(email));
        })
        .catch(() => {
          dispatch(updateAuthRedirect(false));
        });
      return;
    }

    // All the other cases are external providers.
    // Construct provider object for that provider.
    const provider = getProviderForProviderId(providerId);
    // The user may have signed in with an account that has a different email
    // address than the first one. This can happen as Firebase doesn't control the provider's
    // sign in flow and the user is free to login using whichever account they own.
    // To prevent this set hint to pick correct account
    // Attention: 'login_hint' only available for Google provider
    if (providerId === 'google.com') {
      provider.setCustomParameters({
        login_hint: email,
      });
    }
    // At this point, you should let the user know that they already has an account
    // but with a different provider, and let them validate the fact they want to
    // sign in with this provider.
    ConfirmDialog()
      .then(() => {
        if (nativeApp.isNativeApp) {
          const {postMessage, setter} = nativeProviderConfig[providerId]();
          const nativeProvider = getNativeProviderById(providerId);
          triggerNativeFederatedProvider(postMessage, setter, nativeProvider, redirectUrl, dispatch, jwtCookieName);
        } else {
          authWithProvider(provider, dispatch, redirectUrl, jwtCookieName, district);
        }
      })
      .catch(() => {
        dispatch(updateAuthRedirect(false));
      });
  });
};

const getNativeProviderById = providerId => {
  const federatedProvider = {
    'google.com': GoogleAuthProvider,
    'facebook.com': FacebookAuthProvider,
    'apple.com': new OAuthProvider('apple.com'),
  };
  return federatedProvider[providerId];
};

const getProviderForProviderId = providerId => {
  const federatedProvider = {
    'google.com': new GoogleAuthProvider(),
    'facebook.com': new FacebookAuthProvider(),
    'apple.com': new OAuthProvider('apple.com'),
  };
  return federatedProvider[providerId];
};

const fetchEmailSignInMethods = email => fetchSignInMethodsForEmail(auth, email);

const finalHandler = redirectUrl => {
  return window.dialogMode
    ? () => window.top.postMessage(`closeAuthDialog`, '*')
    : () => (window.location.href = redirectUrl);
};

const redirectBackToCaller = (user, redirectUrl, jwtCookieName, providerId, onError) => {
  const search = queryString.parse(window.location.search);

  const callback = finalHandler(redirectUrl);
  // ToDo: Workaround to prevent redirect to `/` for remote authentication
  // Skip following redirect logic to execute redirect to freshdesk by handler onIdTokenChanged
  if (user && search && search.login_service === 'freshdesk') {
    return;
  }
  getIdTokenResult(user)
    .then(decodedToken => {
      if (jwtCookieName) {
        setJwtCookie(jwtCookieName, decodedToken);
      }

      postLogin(decodedToken.token, {success: true, info: null, provider: providerId})
        .then(() => callback())
        .catch(error => {
          Sentry.addBreadcrumb({
            category: 'login',
            message: 'getIdTokenResult in redirectBackToCaller',
            level: 'error',
            data: {
              responseData: error.response?.data,
              requestData: {token: decodedToken.token, providerId},
            },
          });
          Sentry.setTag('providerId', providerId);
          Sentry.captureException(error);
          callback();
        });
    })
    .catch(error => {
      Sentry.captureException(new Error(`Couldn't get JWT token: ${error.message}`, {extra: error.code}));
      if (onError) {
        onError(error);
      } else {
        callback();
      }
    });
};

/**
 * Temporary fix for mistakenly set Cookie in Samsung Internet Browsers
 */
const temporaryDeleteInvalidJwtCookie = cookieName => {
  const currentDomain = document?.location?.hostname ?? 'admin.fupa.net';
  document.cookie = `${cookieName}=;Path=/;domain=.${currentDomain};Expires=Thu, 01 Jan 1970 00:00:01 GMT;max-age=0;`;
};

/**
 * Stores a cookie with the user's JWT to provide it to the redirect target.
 */
const setJwtCookie = (cookieName, decodedIdToken) => {
  if (cookieName) {
    temporaryDeleteInvalidJwtCookie(cookieName);
    const secureFlag = document.location.protocol === 'https:' ? ';Secure' : ''; // allow non-secure cookie for local testing
    // the cookie must only be available on the exact domain, but not for sub domains (must correspond to local storage/indexed db)
    document.cookie = `${cookieName}=${decodedIdToken.token};Path=/${secureFlag};Expires=${decodedIdToken.expirationTime};SameSite=Lax`;
  }
};

const deleteJwtCookie = cookieName => {
  document.cookie = `${cookieName}=;Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;max-age=0;`;
};

const linkAccountsWithEmailAndPassReauthentication = (user, redirectUrl) => {
  const pendingCred = JSON.parse(window.sessionStorage.getItem('pendingCred'));
  let credentials = OAuthCredential.fromJSON(pendingCred);
  linkWithCredential(user, credentials)
    .then(() => {
      // Account successfully linked to the existing Firebase user.
      window.sessionStorage.removeItem('pendingCred');
      getIdTokenResult(user).then(decodedToken => {
        postEmailVerified(decodedToken.token).then(() => {
          window.location = redirectUrl;
        });
      });
    })
    .catch(error => console.log('credentials linked with email provider failed', error));
};

const sendEmailToResetPassword = email => {
  const settings = {
    url: window.location.origin + '/auth/login',
  };
  return sendPasswordResetEmail(auth, email, settings);
};

const emailVerificationDebounced = debouncePromise(async (currentUser, actionCodeSettings) => {
  // Firebase has a limit of 1 email for email verification per minute
  return await sendEmailVerification(currentUser, actionCodeSettings);
}, 60000);

const resendEmailVerification = (currentUser, immediate = false) => {
  if (!Object.keys(currentUser).length) {
    throw new AuthenticationException();
  }
  if (immediate) {
    return sendEmailVerification(currentUser, actionCodeSettings).catch(error =>
      captureErrorSendingEmailVerification({error})
    );
  }
  return emailVerificationDebounced(currentUser, actionCodeSettings).catch(error => {
    if (error instanceof DuplicatePromiseException) {
      return;
    }
    Sentry.captureException(new Error('Rejected verification mail promise'), {extra: {error}});
  });
};

const updateEmail = (currentUser, newEmail) => {
  if (Object.keys(currentUser).length) {
    return firebaseUpdateEmail(currentUser, newEmail);
  }
  throw new AuthenticationException();
};

const ReauthUserWithNativeDialog = async (currentUser, provider) => {
  const authProvider = getNativeProviderById(provider.providerId);
  const {postMessage, setter} = nativeProviderConfig[provider.providerId]();
  const response = await nativeApp.triggerSignIn.handler(postMessage);
  console.log('### flutter response triggerSignIn for', postMessage, ': ', JSON.stringify(response));

  return new Promise((resolve, reject) => {
    const handleNativeReAuthResponse = token => {
      const oAuthToken = setter === 'setAppleToken' ? {idToken: token} : token;
      const credential = authProvider.credential(oAuthToken);
      reauthenticateWithCredential(currentUser, credential)
        .then(response => resolve(response))
        .catch(error => reject(error));
    };
    if (response?.token) {
      handleNativeReAuthResponse(response.token);
    }
    window[setter] = token => {
      handleNativeReAuthResponse(token);
    };
  });
};

const ReauthUserWithRedirect = (currentUser, provider) => {
  const authProvider = getProviderForProviderId(provider.providerId);
  // 'login_hint' only available for Google provider
  if (provider.providerId === 'google.com') {
    authProvider.setCustomParameters({
      login_hint: provider.email,
    });
  }
  // returns Promise<void>, does not trigger 'auth.getRedirectResult'
  reauthenticateWithRedirect(currentUser, authProvider);
};

const ReauthUserWithCredentials = (currentUser, password) => {
  const credential = EmailAuthProvider.credential(currentUser.email, password);
  return reauthenticateWithCredential(currentUser, credential);
};

const updateUserPassword = (newPassword, currentUser) => {
  return updatePassword(currentUser, newPassword);
};

const logLoginError = (error, email, providerId) => {
  const firebaseUser = auth.currentUser;
  let errorInfo = 'unknown error';
  if (error) {
    // firebase analytics error are numeric
    errorInfo = error.code && typeof error.code === 'string' ? error.code : JSON.stringify(error);
  }

  // Whitelist handled error messages
  if (!Object.keys(codes).includes(errorInfo)) {
    Sentry.captureException(error);
  }

  if (firebaseUser) {
    getIdToken(firebaseUser).then(token => {
      if (email) {
        // check still required for facebook logins without provided email
        postLogin(token, {email, success: false, info: errorInfo, provider: providerId}).catch(error => {
          Sentry.addBreadcrumb({
            category: 'login',
            message: 'login error with firebaseUser set',
            level: 'error',
            data: {
              responseData: error.response?.data,
              requestData: {email, providerId, errorInfo},
            },
          });
          Sentry.setTag('providerId', providerId);
          Sentry.captureException(error);
        });
      }
    });
  } else {
    // user failed to authenticate (e. g. wrong password)
    // if the user can't be determined (neither by JWT, nor by email), the failed login can't be logged to the API
    if (email) {
      postLogin(null, {email, success: false, info: errorInfo, provider: providerId}).catch(error => {
        Sentry.addBreadcrumb({
          category: 'login',
          message: 'login error no firebaseUser set',
          level: 'error',
          data: {
            responseData: error.response?.data,
            requestData: {email, providerId, errorInfo},
          },
        });
        Sentry.setTag('providerId', providerId);
        Sentry.captureException(error);
      });
    }
  }
};

const forceTokenRefresh = user => getIdToken(user, true);

const logoutCurrentUser = () => signOut(auth);

const checkExpirationOfToken = (user, expiryTime) => {
  // unix timestamps used for calculation
  const fifteenMinutes = 15 * 60;
  const currentTime = Math.round(new Date().getTime() / 1000);
  if (expiryTime - fifteenMinutes <= currentTime) {
    return forceTokenRefresh(user);
  } else {
    // Needs always to return a promise, otherwise errors are thrown
    return new Promise(resolve => {
      resolve();
    });
  }
};

export {
  initFirebase,
  GoogleLogin,
  AppleLogin,
  FacebookLogin,
  EmailAndPasswordSignUp,
  fetchEmailSignInMethods,
  EmailAndPasswordLogin,
  setJwtCookie,
  deleteJwtCookie,
  linkAccountsWithEmailAndPassReauthentication,
  sendEmailToResetPassword,
  resendEmailVerification,
  updateEmail,
  ReauthUserWithRedirect,
  ReauthUserWithNativeDialog,
  ReauthUserWithCredentials,
  updateUserPassword,
  redirectBackToCaller,
  logLoginError,
  forceTokenRefresh,
  logoutCurrentUser,
  checkExpirationOfToken,
};
